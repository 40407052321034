/* CUSTOM ZENNA */
div.img {
	background-size: contain;
	height: 200px;
	background-repeat: no-repeat;
	background-position: center center;
}

.hover-scale div.img {
	-webkit-transition: all 1.5s ease-out;
	-moz-transition: all 1.5s ease-out;
	-ms-transition: all 1.5s ease-out;
	-o-transition: all 1.5s ease-out;
	transition: all 1.5s ease-out;
}

.hover-scale:hover div.img {
	-webkit-transform: scale(1.1, 1.1);
	-moz-transform: scale(1.1, 1.1);
	-ms-transform: scale(1.1, 1.1);
	-o-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
}

article.entry-item div.entry-wrap {
	padding: 0px;
}

article.entry-item div.entry-img {
	margin: 20px 30px;
}

article.entry-item img.img-circle {
	width: 150px;
	height: 150px;
}

div.recent-posts div.entry-img img {
	width: 50px;
	height: 50px;
}

div.articles div.entry-img img {
	width: 25px;
}

aside.sidebar div.filter-by-size ul {
	overflow: auto;
	max-height: 250px;
}

.single-product .product-list-widget img {
	width: auto;
	margin-bottom: 10px;
}

.product-attributes {
	font-size: 12px;
}

div.shrink a.logo {
	display: none;
}

div.shrink a.logo-sm {
	display: table-cell !important;
}

div.coupon input[name="coupon"] {
	margin-bottom: 0px;
}
